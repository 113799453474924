@charset "utf-8";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  overflow-y: scroll;
}
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-size: 0.875rem;
  background-color: #181818;
  font-family: HelveticaNeue-Light, AppleSDGothicNeo-Light, "Noto Sans", "Malgun Gothic", "맑은 고딕", sans-serif;
  font-weight: 400;
  color: #b6b6b6;
}
html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
  margin: 0;
  padding: 0;
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
label,
input,
button,
select,
img {
  vertical-align: middle;
}
input,
button,
select,
textarea {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue-Light, AppleSDGothicNeo-Light, 'Noto Sans', 'Malgun Gothic', '맑은 고딕', sans-serif;
  font-weight: 400;
  -webkit-border-radius: 0;
  border: 0;
  outline: 0;
  background: transparent;
}
button {
  cursor: pointer;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
select {
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}
select::-ms-expand {
  display: none;
}
p {
  margin: 0;
  padding: 0;
  word-break: break-all;
}
hr {
  display: none;
}
pre {
  overflow-x: scroll;
  font-size: 1.1em;
}
em {
  font-style: normal;
}
a {
  color: #b6b6b6;
  text-decoration: none;
}
a:link,
a:visited {
  color: #b6b6b6;
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  color: #b6b6b6;
  text-decoration: none;
}
input,
button,
select {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue-Light, AppleSDGothicNeo-Light, "Noto Sans", "Malgun Gothic", "맑은 고딕", sans-serif;
  font-weight: 400;
  -webkit-border-radius: 0;
  border: 0;
  outline: 0;
  background: transparent;
}
#app {
  zoom: 1;
  min-height: 100%;
  min-width: 1248px;
}
#header {
  height: 60px;
  max-height: 100%;
  background-color: #212121;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  z-index: 10001;
  display: flex;
}
#header .col-logo {
  padding-left: 22px;
  height: 100%;
  display: flex;
}
#header .col-logo .logo-inner {
  display: flex;
  align-items: center;
  height: 100%;
}
#header .col-logo .logo-inner .folding-menu {
  display: flex;
  padding-right: 16px;
}
#header .col-logo .logo-inner .folding-menu .folding {
  height: 14px;
  vertical-align: middle;
  display: inline-block;
}
#header .col-logo .logo-inner .folding-menu .folding .iconify {
  color: #fff;
}
#header .col-logo .logo-inner .logo {
  height: 100%;
  display: flex;
  align-items: center;
}
#header .col-search {
  height: 100%;
  position: relative;
  flex-grow: 2;
  display: flex;
  justify-content: center;
}
@media (min-width: 700px){
  #header .col-search {
    flex-grow: 2;
  }
}
#header .col-search .search-inner {
  display: flex;
  align-items: center;
  height: 100%;
}
#header .col-search .search-inner div.searchWrapper {
  display: flex;
}

#header .col-search .search-inner div.searchWrapper .search-form {
  background-color: #141414;
  position: relative;
  height: 36px;
  
  display: flex;
  align-items: center;
}
@media (min-width:950px) {
  #header .col-search .search-inner div.searchWrapper .search-form {
    width: 450px;
  }
}
#header .col-search .search-inner div.searchWrapper .search-form input {
  padding-left: 15px;
  width: 100%;
  height: 100%;
  color: #e2e2e2;
  padding-right: 70px;
}
#header .col-search .search-inner div.searchWrapper .search-form .search-res {
  position: absolute;
  right: 15px;
  top: 6px;
}
#header .col-search .search-inner div.searchWrapper .search-form .search-res .submit .iconify {
  color: #b9b9b9;
  font-size: 0.9375rem;
}
#header .col-extra {
  height: 100%;
  padding-right: 22px;
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width:570px){
  #header .col-extra{
    white-space: nowrap;
    padding-right: 10px;
  }
  #header .col-search .search-inner div.searchWrapper .search-form input{
    padding-right: 30px;
  }
}
#header .col-extra .extra-inner {
  align-items: center;
  display: flex;
}
#header .col-extra .extra-inner .member-action {
  display: flex;
  align-items: center;
}
#header .col-extra .extra-inner .member-action span {
  background-color: #363636;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 36px;
  color: white;
  margin: 0 5px;
  cursor: pointer;
}
@media (max-width:510px){
  #header .col-extra .extra-inner .member-action a {
    padding: 0 10px;
    font-size: 10px;
  }
  #header .col-search .search-inner div.searchWrapper .search-form input{
    font-size: 10px;
  }
}
@media (max-width:372px){
  #header .col-extra .extra-inner .member-action a{
    margin: 2px;
    padding: 0 5px;
  }
}
#header .col-extra .extra-inner .member-profile {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  float: left;
  cursor: pointer;
}
#header .col-extra .extra-inner .member-profile .profile-img {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}
#header .col-extra .extra-inner .member-profile .profile-img img {
  border-radius: 33px;
  background-color: #e4e4e4;
}
#header .col-extra .extra-inner .member-profile .profile-nick {
  padding-left: 5px;
}
#header .col-extra .extra-inner .member-profile .profile-nick span {
  color: #e2e2e2;
}
#header .col-extra .extra-inner .member-profile-fold {
  display: none;
  width: 190px;
  z-index: 9090;
  right: 15px;
  top: 83px;
  position: absolute;
  background-color: #212121;
}
#header .col-extra .extra-inner .member-profile-fold.open {
  display: block;
}
#header .col-extra .extra-inner .member-profile-fold ul li {
  display: block;
  border-bottom: 1px solid #343434;
  text-align: left;
}
#header .col-extra .extra-inner .member-profile-fold ul li a {
  padding: 15px 0 15px 15px;
  display: block;
}
#header .col-extra .extra-inner .member-profile-fold ul li a:hover {
  background-color: #2d2d2d;
}
#aside {
  position: fixed;
  left: 0;
  top: 0;
  width: 220px;
  height: 100%;
  z-index: 10000;
  background-color: #212121;
  padding-top: 60px;
  margin: 0;
  transition: all 0.5s ease-in-out;
  float: initial;
}
#aside .home-menu {
  padding-top: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #343434;
  padding-bottom: 15px;
}
#aside .home-menu .rows {
  display: flex;
}
#aside .home-menu .rows a {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 22px;
  width: 100%;
}
#aside .home-menu .rows a:hover {
  background-color: #2d2d2d;
}

#aside .home-menu .rows a.active{
  background-color: #2d2d2d;
}

#aside .home-menu .rows a .iconify {
  width: 20px;
  color: #b6b6b6;
}
#aside .home-menu .rows a strong {
  color: #ececec;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 700;
}
#aside .home-menu .rows a .n-service {
  background-color: #d30000;
  padding: 1px 5px;
  border-radius: 33px;
  font-size: 10px;
  font-size: 0.625rem;
  color: white;
  font-family: Tahoma;
  font-weight: bold;
  margin-left: 7px;
}
#aside .sports-menu {
  padding-top: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #343434;
  padding-bottom: 15px;
}
#aside .sports-menu .rows {
  display: flex;
}


#aside .sports-menu .rows a {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 22px;
  width: 100%;
}
#aside .sports-menu .rows a:hover {
  background-color: #2d2d2d;
}
#aside .sports-menu .rows a .iconify {
  width: 20px;
  font-size: 22px;
  color: #b6b6b6;
}
#aside .sports-menu .rows a strong {
  color: #ececec;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 700;
}
#aside .main-menu {
  display: flex;
  flex-direction: column;
}
#aside .main-menu .menu-list {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 22px;
  border-bottom: 1px solid #343434;
  display: flex;
  flex-direction: column;
  position: relative;
}
#aside .main-menu .menu-list .menu {
  flex-direction: column;
  display: flex;
}
#aside .main-menu .menu-list .menu h2 {
  display: flex;
  align-items: center;
  font-weight: 700;
}
#aside .main-menu .menu-list .menu h2 .iconify {
  width: 20px;
  font-size: 22px;
  color: #b6b6b6;
}
#aside .main-menu .menu-list .menu h2 strong {
  color: #ececec;
  padding-left: 15px;
  font-size: 14px;
}
#aside .main-menu .menu-list .menu h2 .n-service {
  background-color: #d30000;
  padding: 1px 5px;
  border-radius: 33px;
  font-size: 10px;
  font-size: 0.625rem;
  color: white;
  font-family: Tahoma;
  font-weight: bold;
  margin-left: 7px;
}
#aside .main-menu .menu-list .menu ul {
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  padding-top: 10px;
}
#aside .main-menu .menu-list .menu ul li {
  display: block;
}
#aside .main-menu .menu-list .menu ul li a {
  display: block;
  color: #b6b6b6;
  font-size: 13.5px;
  font-size: 0.84375rem;
  padding: 4px 0;
  transition: all 0.1s;
}
#aside .main-menu .menu-list .menu ul li a:hover {
  color: #ececec;
}
#aside .main-menu .menu-list .menu ul li a.active {
  color: #7d0102;
}

#container {
  max-width: 100%;
  transition: all 0.5s ease-in-out;
  padding: 75px 0 0 220px;
}

/* menue handling screen && media screen  */
@media (max-width:720px){
  #aside{
    left: -250px;
  }
  #aside.menue_active{
    left: 0;
  }
  #container {
    padding: 75px 10px 0 10px;
  }
  #movie-container > ul.movie-list{
    padding: 0;
  }
  #header .col-logo .logo-inner .logo{
    display: none;
  }
  #aside .home-menu > .rows.mobile{
    display: flex;
  }
}
@media (min-width:720px){
  #aside .home-menu > .rows.mobile{
    display: none;
  }
}


#container:after {
  clear: both;
  display: block;
}
#container .content {
  width: 1248px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 40px;
  min-height: 600px;
  padding: 25px 2%;
}
@media (max-width:350px){
  #container .content {
    padding: 25px 0;
  }
}
#container .sec-policy-1 {
  height: 43px;
  display: flex;
  background-color: #7d0102;
}
#container .sec-policy-1 .inner {
  width: 1248px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
#container .sec-policy-1 .inner a {
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
#container .sec-policy-2 {
  height: 43px;
  display: flex;
  background-color: white;
}
#container .sec-policy-2 .inner {
  width: 1248px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
#container .sec-policy-2 .inner a {
  color: #7d0102;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
#movie-remocon {
  position: fixed;
  z-index: 999999;
  display: none;
  left: 49%;
  bottom: 30px;
}
#movie-remocon .remocon {
  border-radius: 33px;
  background-color: #0e0e0e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 20px;
}
#movie-remocon .remocon .remocon-controller {
  display: flex;
  flex-direction: column;
}
#movie-remocon .remocon .remocon-controller button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #aaa;
}
#movie-remocon .remocon .remocon-controller button .iconify {
  font-size: 28px;
}
#movie-remocon .remocon .remocon-controller button span {
  font-size: 13px;
  padding-top: 2px;
}
#movie-container {
  position: relative;
}
#movie-container:after {
  clear: both;
  display: block;
  content: '';
}
#movie-container > .movie-header,
#movie-container > .series-header {
  display: flex;
  align-items: center;
  height: 107px;
  max-height: 100%;
}
#movie-container > .movie-header .subject,
#movie-container > .series-header .subject {
  float: left;
  flex: 1;
}
#movie-container > .movie-header .subject h2,
#movie-container > .series-header .subject h2 {
  font-size: 22px;
  color: white;
  font-weight: 400;
}
#movie-container > .movie-header .category,
#movie-container > .series-header .category {
  float: right;
}


  #movie-container .movie-list,
  #movie-container .series-list {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }


@media (min-width:560px){
  #movie-container .movie-list,
  #movie-container .series-list {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
  }
}


@media (min-width:1250px){
  #movie-container .movie-list,
  #movie-container .series-list {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
  }

}
#movie-container .movie-list li,
#movie-container .series-list li {
  max-width: 100%;
  transition: transform 0.3s;
  margin-bottom: 50px;
}
#movie-container .movie-list li a.thumb,
#movie-container .series-list li a.thumb {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-top: 140%;
  display: block;
  border-radius: 3px;
}
#movie-container .movie-list li a.thumb .no-img,
#movie-container .series-list li a.thumb .no-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 50px;
  background-color: #292929;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}
#movie-container .movie-list li a.thumb .no-img .icon .iconify,
#movie-container .series-list li a.thumb .no-img .icon .iconify {
  font-size: 68px;
}
#movie-container .movie-list li a.thumb .no-img .text,
#movie-container .series-list li a.thumb .no-img .text {
  display: flex;
}
#movie-container .movie-list li a.thumb .no-img .text span,
#movie-container .series-list li a.thumb .no-img .text span {
  font-size: 14px;
}
#movie-container .movie-list li a.thumb img,
#movie-container .series-list li a.thumb img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#movie-container .movie-list li .title,
#movie-container .series-list li .title {
  padding: 10px 0;

}
#movie-container .movie-list li .title,
#movie-container .series-list li .title > stron{
  padding: 10px 0;

}
#movie-container .movie-list li .title strong,
#movie-container .series-list li .title strong {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
#movie-container .movie-list li .star span,
#movie-container .series-list li .star span {
  margin-right: 5px;
}
#movie-container .movie-list li .star strong,
#movie-container .series-list li .star strong {
  color: red;
}
.multi-nw-dropdown .multi-select-list .rows a.text-red-400{
  color: rgba(243, 112, 112, 0.719);
}
#movie-container .movie-view,
#movie-container .series-view {
  border-bottom: 1px solid #282828;
  width: 100%;
  max-height: 100%;
  margin-top: 20px;
  position: relative;
}
#movie-container .movie-view:after,
#movie-container .series-view:after {
  clear: both;
  display: block;
  content: '';
}
#movie-container .movie-view .movie-player,
#movie-container .series-view .movie-player {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
#movie-container .movie-view .movie-player .shadow,
#movie-container .series-view .movie-player .shadow {
  position: relative;
  display: block;
  height: 568px;
  width: 100%;
  background: #000;
  z-index: 1;
}
#movie-container .movie-view .movie-player .noonoo-frame,
#movie-container .series-view .movie-player .noonoo-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-width: 100%;
}
#movie-container .movie-view .movie-player .movie-frame,
#movie-container .series-view .movie-player .movie-frame {
  z-index: 100;
  background: #000;
  height: 568px;
  width: 100%;
}

#movie-container .movie-view .movie-player .movie-frame:before,
#movie-container .series-view .movie-player .movie-frame:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIABAEdJRgAAACwAAAAAAQABAAACAkQBAA==');
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
#movie-container .movie-view .movie-player .movie-frame:after,
#movie-container .series-view .movie-player .movie-frame:after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
#movie-container .movie-view .movie-header,
#movie-container .series-view .movie-header {
  padding: 10px 0;
  border-bottom: 1px solid #282828;
  overflow: hidden;
  display: flex;
  align-items: center;
}
#movie-container .movie-view .movie-header .movie-info,
#movie-container .series-view .movie-header .movie-info {
  float: left;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
#movie-container .movie-view .movie-header .movie-info h2,
#movie-container .series-view .movie-header .movie-info h2 {
  font-size: 22px;
  color: white;
}
#movie-container .movie-view .movie-header .movie-info .info,
#movie-container .series-view .movie-header .movie-info .info {
  display: flex;
}
#movie-container .movie-view .movie-header .movie-info .info span,
#movie-container .series-view .movie-header .movie-info .info span {
  padding-right: 7px;
  position: relative;
  color: #b6b6b6;
}
#movie-container .movie-view .movie-header .movie-utility,
#movie-container .series-view .movie-header .movie-utility {
  justify-content: center;
  position: relative;
  display: flex;
  padding: 25px 0;
}
#movie-container .movie-view .movie-header .movie-utility button,
#movie-container .series-view .movie-header .movie-utility button{
  padding-right: 40px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#movie-container .movie-view .movie-header .movie-utility button:hover,
#movie-container .series-view .movie-header .movie-utility button:hover {
  color: #fff;
}
#movie-container .movie-view .movie-header .movie-utility button .icons,
#movie-container .series-view .movie-header .movie-utility button .icons {
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 100%;
}
#movie-container .movie-view .movie-header .movie-utility button span,
#movie-container .series-view .movie-header .movie-utility button span {
  color: #b6b6b6;
  font-size: 14px;
  padding-top: 3px;
}
#movie-container .movie-view .movie-header .movie-utility a span em,
#movie-container .series-view .movie-header .movie-utility a span em {
  padding: 0 5px;
}
#movie-container .movie-view .movie-posted,
#movie-container .series-view .movie-posted {
  padding: 30px 0;
  border-bottom: 1px solid #282828;
  overflow: hidden;
}
#movie-container .movie-view .movie-posted .posted,
#movie-container .series-view .movie-posted .posted {
  position: relative;
}
#movie-container .movie-view .movie-posted .posted .summary,
#movie-container .series-view .movie-posted .posted .summary {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
#movie-container .movie-view .movie-posted .posted .summary h2,
#movie-container .series-view .movie-posted .posted .summary h2 {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: white;
}
#movie-container .movie-view .movie-posted .posted .summary .tag,
#movie-container .series-view .movie-posted .posted .summary .tag {
  line-height: 220%;
  font-size: 14px;
  color: #aaaaaa;
}
#movie-container .movie-view .movie-posted .posted .overview,
#movie-container .series-view .movie-posted .posted .overview {
  width: 100%;
  overflow: hidden;
  padding-top: 18px;
}
#movie-container .movie-view .movie-posted .posted .overview p,
#movie-container .series-view .movie-posted .posted .overview p {
  line-height: 220%;
  font-size: 14px;
  color: #aaaaaa;
}
#movie-container .movie-view .movie-episode,
#movie-container .series-view .movie-episode {
  padding: 30px 0 0px 0;
  border-bottom: 1px solid #282828;
  overflow: hidden;
}
#movie-container .movie-view .movie-episode .movie-episode-header,
#movie-container .series-view .movie-episode .movie-episode-header {
  display: flex;
  padding-bottom: 20px;
}
#movie-container .movie-view .movie-episode .movie-episode-header h2,
#movie-container .series-view .movie-episode .movie-episode-header h2 {
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  color: white;
}
#movie-container .movie-view .movie-episode .movie-episode-header .movie-search,
#movie-container .series-view .movie-episode .movie-episode-header .movie-search {
  display: flex;
  border: 1px solid #282828;
  align-items: center;
  height: 40px;
  position: relative;
  width: 200px;
}
#movie-container .movie-view .movie-episode .movie-episode-header .movie-search input,
#movie-container .series-view .movie-episode .movie-episode-header .movie-search input {
  padding-left: 15px;
  height: 100%;
  width: 90%;
  color: white;
  padding-right: 30px;
}
#movie-container .movie-view .movie-episode .movie-episode-header .movie-search .iconify,
#movie-container .series-view .movie-episode .movie-episode-header .movie-search .iconify {
  position: absolute;
  right: 13px;
  top: 10px;
  font-size: 18px;
}
#movie-container .movie-view .movie-episode .movie-episode-list li,
#movie-container .series-view .movie-episode .movie-episode-list li {
  width: 50%;
  float: left;
  margin-bottom: 20px;
  padding-right: 30px;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .thumb,
#movie-container .series-view .movie-episode .movie-episode-list li .thumb {
  width: 170px;
  max-width: 100%;
  margin-right: 25px;
  position: relative;
  float: left;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .thumb a,
#movie-container .series-view .movie-episode .movie-episode-list li .thumb a {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-top: 65%;
  display: block;
  border-radius: 3px;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .thumb a img,
#movie-container .series-view .movie-episode .movie-episode-list li .thumb a img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .post-episode,
#movie-container .series-view .movie-episode .movie-episode-list li .post-episode {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .post-episode h2,
#movie-container .series-view .movie-episode .movie-episode-list li .post-episode h2 {
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 8px;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .post-episode h2 a,
#movie-container .series-view .movie-episode .movie-episode-list li .post-episode h2 a {
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .post-episode h2 a.active,
#movie-container .series-view .movie-episode .movie-episode-list li .post-episode h2 a.active {
  color: #ff2d2d;
}
#movie-container .movie-view .movie-episode .movie-episode-list li .post-episode .story,
#movie-container .series-view .movie-episode .movie-episode-list li .post-episode .story {
  font-size: 0.875rem;
  color: #646464;
  line-height: 140%;
  height: 3.4375rem;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: clip;
  display: -webkit-box;
  word-wrap: break-word;
}
#movie-container .movie-view .movie-cast,
#movie-container .series-view .movie-cast {
  padding: 30px 0;
  border-bottom: 1px solid #282828;
  overflow: hidden;
}
#movie-container .movie-view .movie-cast h2,
#movie-container .series-view .movie-cast h2 {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding-bottom: 30px;
}
#movie-container .movie-view .movie-cast .cast-list,
#movie-container .series-view .movie-cast .cast-list {
  overflow: hidden;
  position: relative;
}
#movie-container .movie-view .movie-cast .cast-list li,
#movie-container .series-view .movie-cast .cast-list li {
  width: 135px;
  max-width: 100%;
  float: left;
  margin-right: 24px;
}
#movie-container .movie-view .movie-cast .cast-list li .thumb,
#movie-container .series-view .movie-cast .cast-list li .thumb {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-top: 120%;
  display: block;
  border-radius: 7px;
}
#movie-container .movie-view .movie-cast .cast-list li .thumb:after,
#movie-container .series-view .movie-cast .cast-list li .thumb:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  z-index: 1;
  background: linear-gradient(transparent, rgba(20, 20, 20, 0) 20%, rgba(20, 20, 20, 0.2) 50%, rgba(20, 20, 20, 0.5));
}
#movie-container .movie-view .movie-cast .cast-list li .thumb img,
#movie-container .series-view .movie-cast .cast-list li .thumb img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#movie-container .movie-view .movie-cast .cast-list li .actor-name,
#movie-container .series-view .movie-cast .cast-list li .actor-name {
  position: relative;
  display: flex;
  padding-top: 15px;
  flex-direction: column;
}
#movie-container .movie-view .movie-cast .cast-list li .actor-name p,
#movie-container .series-view .movie-cast .cast-list li .actor-name p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}
#movie-container .movie-view .movie-cast .cast-list li .actor-name p.real-name,
#movie-container .series-view .movie-cast .cast-list li .actor-name p.real-name {
  font-size: 15px;
  color: white;
}
#movie-container .movie-view .movie-cast .cast-list li .actor-name p .role-name,
#movie-container .series-view .movie-cast .cast-list li .actor-name p .role-name {
  color: #aaa;
  font-size: 13px;
}
#index {
  padding: 10px 0;
}
#index .suggession .noonoo-info-player {
  display: flex;
  justify-content: center;
  text-align: center;
}
#index .suggession .noonoo-info-player .noonoo-info .link {
  margin-top: 25px;
}
#index .suggession .noonoo-info-player .noonoo-info .link .link-row {
  position: relative;
  margin-top: 10px;
}
#index .suggession .noonoo-info-player .noonoo-info .link .link-row a {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 100%;
  min-height: 10px;
  touch-action: manipulation;
  transition: all 0.2s ease, visibility 0s;
  border-radius: 50px;
  background: #103F54;
  border: 1px solid #207EA9;
  padding: 10px 15px;
  color: #fff;
  font-weight: 700;
}
#index .suggession .noonoo-info-player .noonoo-info .link .link-row:first-child a {
  background: #4A1B0B;
}
#footer {
  position: relative;
  margin-top: 80px;
}
#footer .notice-content {
  height: 57px;
  background-color: #141414;
  width: 100%;
  max-width: 100%;
}
#footer .notice-content .inner {
  width: 1248px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
}
#footer .footer-content {
  width: 1248px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding: 20px 0;
}
#footer .footer-content .link-list {
  display: flex;
  align-items: center;
}
#footer .footer-content .link-list a {
  position: relative;
  font-weight: 700;
}
#footer .footer-content .link-list a:first-child {
  padding-right: 10px;
}
#footer .footer-content .link-list a.liner {
  padding: 0 10px;
}
#footer .footer-content .link-list a.liner:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 11px;
  margin-top: -5px;
  background-color: #323232;
}
#footer .footer-content .copyright {
  padding: 20px 0;
}
#footer .footer-content .copyright p {
  color: #949494;
  line-height: 1.9rem;
  display: block;
  font-size: 13px;
}
#search {
  padding: 0 14px;
  position: relative;
}
#search .search-result .result-header {
  height: 50px;
  display: flex;
  align-items: center;
}
#search .search-result .result-header h2 {
  font-size: 18px;
}
#search .search-result .result-header h2 strong {
  color: #ff1f45;
}
#search .search-result .result-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#search .search-result .result-list .result-row {
  display: flex;
  border-bottom: 1px solid #2b2b2b;
  padding-bottom: 15px;
}
#search .search-result .result-list .result-row .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#search .search-result .result-list .result-row .row .info {
  display: flex;
  padding: 15px 0;
}
#search .search-result .result-list .result-row .row .info .info-left {
  margin-left: 15px;
  flex: 1;
}
#search .search-result .result-list .result-row .row .info .info-left h2 a {
  font-size: 22px;
  color: #fff;
}
#search .search-result .result-list .result-row .row .info .info-left h2 {
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
}
#search .search-result .result-list .result-row .row .info .info-left .overview {
  margin-top: 15px;
  line-height: 35px;
  font-size: 14px;
  color: #aaaaaa;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}
#search .search-result .result-list .result-row .row .info .info-thumb .thumb {
  width: 150px;
  max-width: 100%;
  float: left;
}
#search .search-result .result-list .result-row .row .info .info-thumb .thumb a {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: block;
  border-radius: 3px;
  padding-top: 125%;
}
#search .search-result .result-list .result-row .row .info .info-thumb .thumb a img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#search .search-result .result-paging {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
#scrap {
  padding: 0 14px;
  position: relative;
}
#scrap .scrap-box .scrap-header {
  height: 50px;
  display: flex;
  align-items: center;
}
#scrap .scrap-box .scrap-header h2 {
  font-size: 18px;
  color: #fff;
}
#scrap .scrap-box .scrap-header h2 strong {
  color: #ff1f45;
}
#scrap .scrap-box .scrap-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#scrap .scrap-box .scrap-list .scrap-row {
  display: flex;
  border-bottom: 1px solid #2b2b2b;
  padding-bottom: 15px;
}
#scrap .scrap-box .scrap-list .scrap-row .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info {
  display: flex;
  padding: 15px 0;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-left {
  margin-left: 15px;
  flex: 1;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-left h2 a {
  font-size: 22px;
  color: #fff;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-left h2 {
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-left .overview {
  margin-top: 15px;
  line-height: 35px;
  font-size: 14px;
  color: #aaaaaa;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-thumb .thumb {
  width: 150px;
  max-width: 100%;
  float: left;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-thumb .thumb a {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: block;
  border-radius: 3px;
  padding-top: 125%;
}
#scrap .scrap-box .scrap-list .scrap-row .row .info .info-thumb .thumb a img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#scrap .scrap-box .result-paging {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
#mypage .mypage-header h2 {
  color: white;
  padding: 50px 0 30px 0;
  font-size: 19px;
  font-size: 1.1875rem;
}
#mypage .mypage-content {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}
#mypage .mypage-content .rows {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}
#mypage .mypage-content .rows .name {
  width: 150px;
  color: white;
}
#mypage .mypage-content .rows span input {
  height: 35px;
  color: #e2e2e2;
  background-color: #212121;
  padding-left: 10px;
}
#mypage .mypage-content .submit {
  padding-top: 10px;
}
#mypage .mypage-content .submit .btn-submit {
  border: 1px solid #343434;
  color: white;
  width: 130px;
  height: 45px;
}
.content-banner.content-banner-view {
  padding: 15px 0 0 0;
}
.window-scroll {
  position: fixed;
  bottom: 35px;
  right: 35px;
  background-color: #1348d5;
  border-radius: 33px;
  display: none;
}
.window-scroll a {
  display: flex;
  border-radius: 33px;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
}
.window-scroll a .iconify {
  color: #fff;
  font-size: 22px;
}
.noonoo-info .site-info {
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}
.noonoo-info .site-info .logo {
  display: flex;
  justify-content: center;
}
.noonoo-info .site-info .site-link {
  margin-top: 25px;
}
.noonoo-info .site-info .site-link .link-row {
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.noonoo-info .site-info .site-link .link-row a {
  display: block;
  width: 185px;
  height: 30px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  min-height: 10px;
  touch-action: manipulation;
  transition: all 0.2s ease, visibility 0s;
  border-radius: 50px;
  background: #103F54;
  border: 1px solid #207EA9;
  padding: 5px 0;
  color: #fff;
  font-weight: 700;
}
.noonoo-info .site-info .site-link .link-row:first-child a {
  background: #4A1B0B;
}
.noonoo-info .site-banner {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
.noonoo-info .site-banner .banner {
  background: #222;
  width: 100%;
  height: 100%;
}
.noonoo-info .site-banner .banner a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.noonoo-info .site-banner .banner a .blank {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #222;
  border: 1px solid #333;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.noonoo-info .site-banner .banner a .blank:after {
  content: ' ';
}
.noonoo-info .site-banner .banner a img {
  z-index: 10;
  max-width: 100%;
}
.paging {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
/* 페이징 */
.pg_wrap {
  clear: both;
  float: left;
  display: inline-block;
}
.pg_wrap:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.pg {
  text-align: center;
}
.pg_page,
.pg_current {
  border-radius: 33px;
  padding: 8px 0;
  color: white;
  background-color: #303030;
  margin: 0 5px;
  min-width: 35px;
  text-align: center;
}
.pg a:focus,
.pg a:hover {
  text-decoration: none;
}
.pg_page {
  color: #959595;
  font-size: 1.083em;
  height: 30px;
  line-height: 28px;
  padding: 0 5px;
  min-width: 30px;
  text-decoration: none;
  border-radius: 3px;
}
.pg_page:hover {
  background-color: #fafafa;
}
.pg_start {
  text-indent: -999px;
  overflow: hidden;
  /* background: url('../img/btn_first.gif') no-repeat 50% 50% #eee; */
  padding: 0;
  border: 1px solid #eee;
}
.pg_prev {
  text-indent: -999px;
  overflow: hidden;
  /* background: url('../img/btn_prev.gif') no-repeat 50% 50% #eee; */
  padding: 0;
  border: 1px solid #eee;
}
.pg_end {
  text-indent: -999px;
  overflow: hidden;
  /* background: url('../img/btn_end.gif') no-repeat 50% 50% #eee; */
  padding: 0;
  border: 1px solid #eee;
}
.pg_next {
  text-indent: -999px;
  overflow: hidden;
  /* background: url('../img/btn_next.gif') no-repeat 50% 50% #eee; */
  padding: 0;
  border: 1px solid #eee;
}
.pg_start:hover,
.pg_prev:hover,
.pg_end:hover,
.pg_next:hover {
  background-color: #fafafa;
}
.pg_current {
  display: inline-block;
  background: #3a8afd;
  border: 1px solid #3a8afd;
  color: #fff;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  min-width: 30px;
  border-radius: 3px;
}
/* 캡챠 자동등록(입력)방지 기본 -pc */
#captcha {
  display: inline-block;
  position: relative;
}
#captcha legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
#captcha #captcha_img {
  height: 40px;
  border: 1px solid #898989;
  vertical-align: top;
  padding: 0;
  margin: 0;
}
#captcha #captcha_mp3 {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url('../../../img/captcha2.png') no-repeat; */
  text-indent: -999px;
  border-radius: 3px;
}
#captcha #captcha_reload {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url('../../../img/captcha2.png') no-repeat 0 -40px; */
  text-indent: -999px;
  border-radius: 3px;
}
#captcha #captcha_key {
  margin: 0 0 0 3px;
  padding: 0 5px;
  width: 90px;
  height: 40px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 1.333em;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  vertical-align: top;
}
#captcha #captcha_info {
  display: block;
  margin: 5px 0 0;
  font-size: 0.95em;
  letter-spacing: -0.1em;
}
/* 캡챠 자동등록(입력)방지 기본 - mobile */
#captcha.m_captcha audio {
  display: block;
  margin: 0 0 5px;
  width: 187px;
}
#captcha.m_captcha #captcha_img {
  width: 160px;
  height: 60px;
  border: 1px solid #e9e9e9;
  margin-bottom: 3px;
  margin-top: 5px;
  display: block;
}
#captcha.m_captcha #captcha_reload {
  position: static;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url('../../../img/captcha2.png') no-repeat 0 -40px; */
  text-indent: -999px;
}
#captcha.m_captcha #captcha_reload span {
  display: none;
}
#captcha.m_captcha #captcha_key {
  margin: 0;
  padding: 0 5px;
  width: 115px;
  height: 29px;
  border: 1px solid #b8c9c2;
  background: #f7f7f7;
  font-size: 1.333em;
  font-weight: bold;
  text-align: center;
  line-height: 29px;
  margin-left: 3px;
}
#captcha.m_captcha #captcha_info {
  display: block;
  margin: 5px 0 0;
  font-size: 0.95em;
  letter-spacing: -0.1em;
}
#captcha.m_captcha #captcha_mp3 {
  width: 31px;
  height: 31px;
  /* background: url('../../../img/captcha2.png') no-repeat 0 0; */
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  text-indent: -9999px;
  border: none;
}


.search_resul {
  position: absolute;
  bottom: -340px;
  min-height: 350px;
  max-height: 380px;
  background-color: rgba(27, 25, 25, 0.589);
  max-width: 350px;
  padding: 20px;
  border-radius: 20px;
  min-width: 350px;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.search_resul::-webkit-scrollbar {
  display: none;
}
.search_resul.active{
  display: block;
}
.search_resul.inActive{
  display: none;
}

.search_resul ul > li {
  padding: 10px 5px;
  color: white;
}

.search_resul ul li > a {
  color: white;
}

.search_resul ul > li:hover{
  background: rgba(3, 3, 3, 0.658);
}

@media (min-width: 450px) {
  .search_resul {
    min-width: 450px;
    bottom: -380px;
  }
  #aside .main-menu .menu-list.authentication{
    display: none;
  }
}
@media (max-width: 650px) {
  .search_resul {
    transform: translateX(-30%);
  }
}
@media (max-width: 450px) {
  .search_resul {
    transform: translateX(-50%);
  }
}
@media (max-width: 450px) {
  .search_resul {
    top: 3rem;
  }
  #header .col-extra{
    display: none;
  }
  .search-inner{
    width: 100%;
  }
  .searchWrapper{
    width: 100%;
  }
  #header .search-form{
    width: 100%;
  }
}