* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

body {
  font-family: 'Nunito', sans-serif;
}

body::-webkit-scrollbar {
  width: 0px; 
}

body::-webkit-scrollbar-track {
  background: lightgray;
}

body::-webkit-scrollbar-thumb {
  background-color: #198754;
  border-radius: 20px;
}

span.swiper-pagination-bullet-active{
  background-color: #770909;
}
div.swiper-button-prev, div.swiper-button-next{
 color: white;
 font-size: 10px;
}
div.swiper-button-prev, div.swiper-button-next{
 width: 25px;
 height: 25px;
 background-color: #770909;
 border-radius: 50%;
}
div.swiper-button-prev:after, div.swiper-button-next:after{
  font-size: 16px;
}