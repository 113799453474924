.ep_container{
 padding-bottom: 15px;
}
.ep_container .ep_wrapper{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
}
.ep_container .ep_wrapper > li{
    padding:5px 15px;
    background-color: #7D0102;
}
.ep_container .ep_wrapper > li.active{
    padding:5px 15px;
    background-color: #ffffff;
    color: #7D0102;
}

.ep_container .ep_wrapper > li.active > a{
    color: #7D0102;
}