li.pageActive > .page-link{
    background-color: rgb(248 113 113);
    color: white;
}
a.page-link{
    background-color: transparent;
    margin: 0 5px;
    color: white;
}
li.page-item.nexitem{
    border:1px solid rgb(248 113 113);
}

@media (max-width:550px){
    a.page-link{
        font-size: .5rem;
        padding: 5px 8px;
        padding-bottom: 4px;
        margin: 0 2px;
    }
    li.page-item.nexitem{
        margin-left: 0;
        padding: 0px 0px;
    }
    li.page-item.disabled{
        margin-right: 2px;
    }
}
@media (min-width:550px){
    li.page-item.nexitem{
        border:1px solid rgb(248 113 113);
        margin-left: 5px;
    }
    li.page-item.disabled{
        margin-right: 10px;
    }
}
@media (max-width:300px){
    a.page-link{
        padding:3px 5px;
       
    }
}

li.page-item.disabled{
    border:1px solid rgb(248 113 113);
}

li.page-item.disabled .page-link{
    border-radius: 0;
    background-color: transparent;
    border: 0;
    color: white;
    
}

li.page-item.nexitem .page-link{
    border-radius: 0;
    background-color: transparent;
    border: 0;
    color: white;
}