.menuicon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    position: relative;
    width: 20px; /* $u * 2.5 */
    height: 20px; /* $u * 2.5 */
    cursor: pointer;
  }
  
  .menuicon.active .menuicon__dot {
    opacity: 0;
  }
  
  .menuicon.active .menuicon__dot:nth-child(2) {
    opacity: 1;
    height: 20px; /* $u * 2.5 */
    transform: rotate(45deg);
  }
  
  .menuicon.active .menuicon__dot:nth-child(4) {
    opacity: 1;
    width: 20px; /* $u * 2.5 */
    transform: rotate(45deg);
  }
  
  .menuicon__wrapper {
    width: 32px; /* $u * 4 */
    height: 32px; /* $u * 4 */
    flex: 0 0 32px; /* $u * 4 */
    min-width: 32px; /* $u * 4 */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .menuicon__dot {
    width: 4px; /* $u * 0.5 */
    height: 4px; /* $u * 0.5 */
    background: #ffffff; /* $black */
    transition: all cubic-bezier(0.5, 1, 0.89, 1) 0.3s, transform 0.3s cubic-bezier(0.5, 1, 0.89, 1) 0.3s;
    border-radius: 8px; /* $u */
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .menuicon__dot:nth-child(1) {
  }
  
  .menuicon__dot:nth-child(2) {
    left: 8px; /* $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(3) {
    left: 16px; /* $u * 0.5 + $u * 0.5 + $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(4) {
    top: 8px; /* $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(5) {
    left: 8px; /* $u * 0.5 + $u * 0.5 */
    top: 8px; /* $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(6) {
    left: 16px; /* $u * 0.5 + $u * 0.5 + $u * 0.5 + $u * 0.5 */
    top: 8px; /* $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(7) {
    top: 16px; /* $u * 0.5 + $u * 0.5 + $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(8) {
    left: 8px; /* $u * 0.5 + $u * 0.5 */
    top: 16px; /* $u * 0.5 + $u * 0.5 + $u * 0.5 + $u * 0.5 */
  }
  
  .menuicon__dot:nth-child(9) {
    left: 16px; /* $u * 0.5 + $u * 0.5 + $u * 0.5 + $u * 0.5 */
    top: 16px; /* $u * 0.5 + $u * 0.5 + $u * 0.5 + $u * 0.5 */
  }
  